





































import { Vue, Component, Ref, PropSync, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import _ from 'lodash'

type FormData = { visible: string[]; editable: string[] }
type Account = AnyObj

enum Authority {
  DELETED = 0,
  VISIBLE = 5,
  EDITABLE = 10
}

@Component
export default class AccountDialog extends Vue {
  @PropSync('visible') syncedVisible: boolean
  @Prop() tagId: string
  @Prop() authority: Authority

  @Ref() form: ElForm

  formData: FormData = { visible: [], editable: [] }
  formLoading = false
  loading = false
  accounts: Account[] = []
  initialAccounts: Account[] = []

  get options() {
    return this.accounts.map(({ id, name }) => ({
      value: id,
      label: name,
      disabled: this.getOptionDisabled(id)
    }))
  }

  get editableOptions() {
    return this.accounts
      .filter(({ id }) => this.formData.visible.includes(id))
      .map(({ id, name }) => ({ value: id, label: name }))
  }

  async mounted() {
    this.formLoading = true

    await Promise.all([this.fetchAccounts(), this.fetchAccountWithTags()])

    this.formLoading = false
  }

  getOptionDisabled(id: string) {
    return this.initialAccounts.find(a => a.id === id)?.authority > this.authority
  }

  async fetchAccounts() {
    const { data } = await this.$api.mind.searchAccount()

    if (data.code === 0) {
      this.accounts = data.data
    }
  }

  async fetchAccountWithTags() {
    const { data } = await this.$api.mind.getAccountsWithTag(this.tagId)

    if (data.code === 0) {
      const accounts = data.data as Account[]

      this.formData = {
        visible: accounts.map(({ id }) => id),
        editable: accounts
          .filter(({ authority }) => authority === Authority.EDITABLE)
          .map(({ id }) => id)
      }
      this.initialAccounts = data.data
    }
  }

  onVisibleAccountsChange(value: string[]) {
    this.formData.editable = this.formData.editable.filter(i => value.includes(i))
  }

  close() {
    this.syncedVisible = false
    this.formData = { visible: [], editable: [] }
    this.initialAccounts = []
  }

  cancel() {
    this.close()
  }

  // 参考 src/views/AdminManagement/AccountManagement/TagDialog.vue
  async confirm() {
    const bulk = (accountId: string, authority: Authority) => ({
      tagId: this.tagId,
      accountId,
      authority
    })

    const map = new Map<string, Authority>()

    const accounts = _.without(this.formData.visible, ...this.formData.editable)
      .map(id => ({ id, authority: Authority.VISIBLE }))
      .concat(this.formData.editable.map(id => ({ id, authority: Authority.EDITABLE })))

    accounts.forEach(({ id, authority }) => {
      map.set(id, authority)
    })

    this.initialAccounts.map(({ id, authority }) => {
      if (map.has(id)) {
        if (map.get(id) === authority) {
          map.delete(id)
        }
      } else {
        map.set(id, Authority.DELETED)
      }
    })

    this.loading = true

    const { status } = await this.$api.mind.bindTagAccount({
      bulk: Array.from(map.entries()).map(([id, authority]) => bulk(id, authority))
    })

    this.loading = false

    if (status === 204) {
      this.close()
    }
  }
}
