



















































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import InputSearch from '@/components/common/InputSearch.vue'
import treeFind from '@/libs/operation-tree-node/treeFind'
import { record, is } from '@/utils/helpers'
import { ElTable } from 'element-ui/types/table'
import { ElTree } from 'element-ui/types/tree'
import stores, { actionTypes } from '@/store'

@Component({ components: { InputSearch } })
export default class BindDevices extends Vue {
  @Ref('areaTree') private readonly areaTree: ElTree<any, any>
  @Ref('table') private readonly table: ElTable
  @Ref() inputSearch: InputSearch
  @Ref() tagsInputSearch: InputSearch
  @Ref() areaInputSearch: InputSearch
  private deviceList: AnyObj[] = []
  private currentAreaId = ''
  private selection: AnyObj[] = []
  private visible = false
  private filterData: AnyObj = {
    limit: 100,
    offset: 0,
    areaIds: [],
    cascade: true,
    name: ''
  }
  private tagsFilterValue = ''
  private loading = false
  private areaList: AnyObj[] = []
  private tagId = ''
  private submitLoading = false
  deviceTotalNum = 0
  firstOpen = true

  private get filteredDevices() {
    return this.selection.filter(({ physicalId }) => physicalId.indexOf(this.tagsFilterValue) > -1)
  }
  async fetchAreaList() {
    const areas = await stores.dispatch(actionTypes.FETCH_BASE_AREAS)

    if (areas) {
      this.areaList = areas
      this.currentAreaId = areas[0].id
      this.fetchDeviceList()
    }
  }

  private getRowKey(row: AnyObj) {
    return row.id
  }

  private getAreaName(areaId: string) {
    const area = treeFind(this.areaList, ({ id }) => id === areaId)
    return area ? area.name : ''
  }

  private getTableBody() {
    if (this.table) {
      return this.table.$el.querySelector<HTMLDivElement>('.el-table__body-wrapper')
    }

    return null
  }

  private async lazyLoad() {
    const tableBody = this.getTableBody()
    this.$nextTick(() => {
      if (tableBody) {
        const listener = () => {
          const { scrollHeight, scrollTop, clientHeight } = tableBody
          const scrollDistance = scrollHeight - scrollTop - clientHeight

          if (scrollDistance <= 0) {
            const offset = this.deviceList.length
            this.fetchDeviceList({ lazy: true, offset })
          }
        }

        tableBody.addEventListener('scroll', listener)
        this.lazyLoadListener = listener
      }
    })
  }

  private removeLazyLoad() {
    const tableBody = this.getTableBody()
    this.$nextTick(() => {
      if (tableBody && this.lazyLoadListener) {
        tableBody.removeEventListener('scroll', this.lazyLoadListener)
      }
    })
  }

  private async fetchDeviceList({ lazy = false, offset = 0, limit = 20, canFetch = false } = {}) {
    if (
      this.currentAreaId &&
      (this.deviceList.length < this.deviceTotalNum ||
        this.firstOpen ||
        canFetch ||
        (this.deviceList.length == 0 && this.deviceTotalNum == 0))
    ) {
      if (this.firstOpen) this.firstOpen = false
      this.loading = true

      const { data } = await this.$api.base.getDevicesSearch({
        ...record(this.filterData).filter(is.ava),
        offset,
        limit,
        areaIds: [this.currentAreaId]
      })

      this.loading = false

      if (data.code === 0) {
        if (lazy) {
          this.deviceList.splice(offset, this.deviceList.length - offset, ...data.data)
        } else {
          const tableBody = this.getTableBody()
          if (tableBody) tableBody.scrollTop = 0
          this.deviceList = data.data
        }
        this.deviceTotalNum = data.count
        this.setTableSelection()
      }
    }
  }

  private areaInputChange(value: string) {
    this.areaTree.search(value)
  }

  private tableInputChange(value: string) {
    this.filterData.physicalId = value
    this.fetchDeviceList({ canFetch: true })
  }

  private tagsInputChange(value: string) {
    this.tagsFilterValue = value
  }

  private areaClick(area: AnyObj) {
    this.currentAreaId = area.id
    this.fetchDeviceList({ canFetch: true })
  }

  private setTableSelection() {
    this.$nextTick(() => {
      if (this.table) {
        this.table.store.states.selection = this.selection
      }
    })
  }

  private select(selection: AnyObj[]) {
    this.selection = selection
    this.setTableSelection()
  }

  private remove(row: AnyObj) {
    this.selection = this.selection.filter(({ id }) => row.id !== id)
    const { selection } = this.table.store.states

    this.table.toggleRowSelection(
      selection.find(({ id }: AnyObj) => id === row.id),
      false
    )
  }

  private clear() {
    this.selection = []
    this.$nextTick(() => this.table.clearSelection())
  }

  public async show(value: AnyObj) {
    this.visible = true

    this.tagId = value.id
    const { data } = await this.$api.base.getTagDevices(this.tagId)
    this.fetchAreaList()
    if (data && data.code === 0) {
      this.select(data.data.data)
    }
    this.fetchDeviceList({ canFetch: true })
  }

  private opened() {
    this.$nextTick(() => {
      this.areaTree.setCurrentKey(this.currentAreaId)
      this.lazyLoad()
    })
  }

  private async submit() {
    if (this.selection.length === 0) {
      this.$message({ message: '至少选择一个设备进行绑定', type: 'error' })
      return
    }
    this.submitLoading = true
    const deviceIds = this.selection.map(i => i.id)
    const { data } = await this.$api.base.postTagDevices(this.tagId, { deviceIds })
    if (data && data.code === 0) {
      this.close()
      this.$message({ message: '绑定成功', type: 'success' })
    }
    this.submitLoading = false
  }

  private close() {
    this.areaInputSearch.restore()
    this.tagsInputSearch.restore()
    this.inputSearch.restore()
    this.filterData.physicalId = ''
    this.currentAreaId = ''
    this.areaList = []
    this.deviceList = []
    this.selection = []
    this.table.clearSelection()
    this.removeLazyLoad()
    this.visible = false
  }
}
